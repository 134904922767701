<template>
    <router-link :to="link" class="block hover:bg-gray-50 dark:hover:bg-gray-770 border-t border-gray-200 dark:border-gray-700">
      <div class="px-4 py-4 sm:px-6 flex items-center flex-wrap sm:flex-nowrap">
        <div class="grid grid-cols-12 w-full">

          <div class="col-span-12 lg:col-span-3 xl:col-span-2">
            <div class="mr-10 py-1">
              <p class="mt-2 flex text-md text-gray-900 dark:text-gray-300 sm:mt-0">
                <span class="text-gray-400 mr-1">
                  <HomeIcon class="w-6 h-6" />
                </span>
                {{ item.name }}
              </p>
            </div>
          </div>

          <div class="col-span-12 lg:col-span-6 xl:col-span-5">
            <div class="mr-10 py-1">
              <p class="mt-2 flex text-md text-gray-900 dark:text-gray-300 sm:mt-0">
                <span class="text-gray-400 mr-1">
                  <LocationMarkerIcon class="w-6 h-6" />
                </span>
                {{ item.address ? item.address : 'Nav norādīts' }}
              </p>
            </div>
          </div>

          <div class="col-span-12 lg:col-span-3">
            <div class="mr-10 py-1">
              <p class="mt-2 flex text-md text-gray-900 dark:text-gray-300 sm:mt-0">
                <span class="text-gray-400 mr-1">
                  <MapIcon class="w-6 h-6" />
                </span>
                {{ item.region ? item.region.name : 'Nav norādīts' }}
              </p>
            </div>
          </div>

        </div>

        <div class="ml-auto">
          <p class="mt-2 flex items-center text-sm text-gray-900 dark:text-gray-300 sm:mt-0">
            <button class="text-gray-400 flex items-center whitespace-nowrap">
              Skatīt filiāles
              <ChevronRightIcon class="w-6 h-6"/>
            </button>
          </p>
        </div>

      </div>
    </router-link>
</template>

<script>

import {
  HomeIcon,
  LocationMarkerIcon,
  ChevronRightIcon,
} from "@heroicons/vue/solid";
import {
  MapIcon,
} from "@heroicons/vue/outline";

export default {
  name: "ShowCustomerBranch",
  components: {
    HomeIcon,
    LocationMarkerIcon,
    ChevronRightIcon,
    MapIcon
  },
  props: {
    link: {
      type: String,
      default: null
    },
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    changeStatus() {
      if (confirm("Vai esi drošs, ka vēlies mainīt statusu?")) {
          this.$Progress.start();
          this.$store.dispatch("updateRentDetails", {
              id: this.item.id,
              is_accepted: true,
          }).then( response => {
              this.$store.dispatch('getRents', 1)
          });
      }
    },
  }
}
</script>

<style>

</style>