<template>
  <router-link :to="link" class="block hover:bg-gray-50 dark:hover:bg-gray-770 border-t border-gray-200 dark:border-gray-700">
    <div class="px-4 py-4 sm:px-6 flex flex-wrap sm:flex-nowrap items-center">
      <div class="grid grid-cols-12 w-full">

        <div class="col-span-12 sm:col-span-7 lg:col-span-4 xl:col-span-3">
          <div class="mr-10 py-1">
            <p class="mt-2 flex items-center text-md text-gray-900 dark:text-gray-300 sm:mt-0">
              <span class="text-gray-400 mr-1">
                <UserIcon class="w-6 h-6" />
              </span>
              {{ item.name }}
            </p>
          </div>
        </div>

        <div class="col-span-12 sm:col-span-4 lg:col-span-4 xl:col-span-3">
          <div class="mr-10 py-1">
            <p class="mt-2 flex items-center text-md text-gray-900 dark:text-gray-300 sm:mt-0">
              <span class="text-gray-400 mr-1">
                <PhoneIcon class="w-6 h-6"/>
              </span>
              {{ item.phone ? item.phone : 'Nav norādīts' }}
            </p>
          </div>
        </div>

        <div class="col-span-12 lg:col-span-4">
          <div class="mr-10 py-1">
            <p class="mt-2 flex items-center text-md text-gray-900 dark:text-gray-300 sm:mt-0">
              <span class="text-gray-400 mr-1">
                <MailIcon class="w-6 h-6" />
              </span>
              {{ item.email ? item.email : 'Nav norādīts' }}
            </p>
          </div>
        </div>
      </div>
      
      <div class="ml-auto">
        <p class="mt-2 flex items-center text-sm text-gray-900 dark:text-gray-300 sm:mt-0">
          <button class="text-gray-400 flex items-center whitespace-nowrap">
            Skatīt kontaktus
            <ChevronRightIcon class="w-6 h-6"/>
          </button>
        </p>
      </div>
    </div>
  </router-link>
</template>

<script>
import {
  UserIcon,
  PhoneIcon,
  MailIcon,
  ChevronRightIcon,
} from "@heroicons/vue/solid";

export default {
  name: "ShowCustomerContact",
  components: {
    UserIcon,
    PhoneIcon,
    MailIcon,
    ChevronRightIcon,
  },
  props: {
    link: {
      type: String,
      default: null
    },
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    changeStatus() {
      if (confirm("Vai esi drošs, ka vēlies mainīt statusu?")) {
          this.$Progress.start();
          this.$store.dispatch("updateRentDetails", {
              id: this.item.id,
              is_accepted: true,
          }).then( response => {
              this.$store.dispatch('getRents', 1)
          });
      }
    },
  }
}
</script>

<style>

</style>