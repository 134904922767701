<template>
  <li>
    <item-card :url="`/customers/${item.id}`">
      <template v-slot:title>
        <h3 class="text-lg leading-6 font-medium">
          {{item.name}}
        </h3>
      </template>

      <template v-slot:buttons>
        <span class="relative z-0 inline-flex rounded-md ml-3">

          <template v-if="item.branches.length > 0">
            <button type="button" class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 focus:z-10 focus:outline-none focus:ring-0" :class="[showBranch ? 'bg-gray-100 dark:bg-gray-770' : '', item.contacts.length > 0 ? '' : 'rounded-r-md']" @click="showBranch = !showBranch">
              Filiāle
            </button>
          </template>

          <template v-if="item.contacts.length > 0">
            <button type="button" class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 focus:z-10 focus:outline-none focus:ring-0" :class="[showContact ? 'bg-gray-100 dark:bg-gray-770' : '', item.branches.length > 0 ? '' : 'rounded-l-md']" @click="showContact = !showContact">
              Kontakti
            </button>
          </template>
        </span>
      </template>

      <template v-slot:content>
        <ItemText>
          <template v-slot:content>
            <div class="h-full flex items-center">
              <div class="w-9 h-9 bg-gray-400 rounded-full flex justify-center align-center text-white">
                <span class="text-2xl">Kl</span>
              </div>
            </div>
          </template>
        </ItemText>
        <ItemText title="Reģ. nr/PK" :text="item.reg_nr ? item.reg_nr : 'Nav norādīts'" />
        <ItemText title="Adrese" :text="item.address ? item.address : 'Nav norādīts'" />
      </template>

      <template v-slot:additional>
        <template v-if="showBranch && item.branches">
          <div class="pb-2">
            <ShowCustomerBranch v-for="branch in item.branches" :key="branch.id" :link="`/customers/${branch.customer_id}/branches/${branch.id}`" :item="branch" />
          </div>
        </template>

        <template v-if="showContact && item.contacts">
          <div class="pb-2">
            <ShowCustomerContact v-for="contact in item.contacts" :key="contact.id" :link="`/contacts/${contact.id}`" :item="contact" />
          </div>
        </template>
      </template>
    </item-card>
  </li>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import ShowCustomerContact from "@/components/Customers/AdditionalFields/ShowCustomerContact"
import ShowCustomerBranch from "@/components/Customers/AdditionalFields/ShowCustomerBranch"

export default {
  name: "ShowCustomer",
  data() {
    return {
      showContact: false,
      showBranch: false,
    }
  },
  components: {
    ItemCard,
    ItemText,
    ShowCustomerContact,
    ShowCustomerBranch
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>

</style>